@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  position: relative;
  color: #1e1e1e;
}

.custom-bullet {
  position: relative;
  padding-left: 14px;
}

.custom-bullet::before {
  content: '';
  position: absolute;
  top: 10px;
  left: 0;
  font-size: 1em;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background-color: black;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

.error-container {
  svg * {
    fill: #f26739;
  }
}

.hole-ticket {
  height: 78px;

  background: radial-gradient(circle at 0% 50%, transparent 70%, white 30%) no-repeat,
    radial-gradient(circle at 100% 50%, transparent 70%, white 30%) no-repeat;
}

.bg-scoop-left {
  background: radial-gradient(circle at 0% 50%, transparent 70%, white 30%) no-repeat;
}

.bg-scoop-left .shadow {
  box-shadow: inset -4px 1px 4px 0px #afafaf40;
}

.bg-scoop-right {
  background: radial-gradient(circle at 100% 50%, transparent 70%, white 30%) no-repeat;
}

.bg-scoop-right .shadow {
  box-shadow: inset 4px 1px 4px 0px #afafaf40;
}

body:has(.drawer) {
  overflow: hidden;
}

body:has(.pwa-install-guide):not(:has(.no-data)) {
  padding-bottom: 296px;
}

.ios .min-h-screen {
  min-height: 100dvh !important;
}
.ios .h-screen {
  height: 100dvh !important;
}

* {
  -webkit-box-sizing: border-box; /* Safari/Chrome, other WebKit */
  -moz-box-sizing: border-box; /* Firefox, other Gecko */
  box-sizing: border-box; /* Opera/IE 8+ */
}

.clip-path-top {
  clip-path: inset(0px -4px -4px -4px);
}

.clip-path-bottom {
  clip-path: inset(-4px -4px 0 -4px);
}

.user-ticket-item {
  overflow: visible;
  max-height: 62px;
  transition: all 0.25s ease-in-out;
}

.user-ticket-item:last-child {
  min-height: 537px;
}

.user-ticket-item.is-selected {
  max-height: 537px;
}

.user-digital-certificate-item {
  overflow: visible;
  max-height: 62px;
  transition: all 0.25s ease-in-out;
}

.user-digital-certificate-item:last-child {
  min-height: 537px;
}

.user-digital-certificate-item.is-selected {
  max-height: 537px;
}

.primary-color-icon svg path {
  stroke: #1e1e1e;
  fill: #1e1e1e;
}
::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background: #e3e4e7;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: #ccc;
}

/* HTML: <div class="parallelogram"></div> */
.parallelogram {
  --p: 16px; /* control the shape (can be percentage) */
  clip-path: polygon(var(--p) 0, 100% 0, 100% 100%, 0 100%);
  background: white;
}

.container:has(.no-layout) {
  padding-inline: 0;
  padding-bottom: 0;
}

.container:has(.no-layout) .header {
  padding-inline: 16px;
}
